import Vue from 'vue'
import vuetify from './plugins/vuetify'
import { VApp } from "vuetify/lib";
import vueCustomElement from 'vue-custom-element'
import HomePage from '@/views/HomeProd.vue'

Vue.prototype.$prodPrefix = process.env.VUE_APP_FRONT_OFFICE_BASE_URL;

Vue.use(vueCustomElement);
  
// HomePage component
Vue.customElement('iupac-home-custom-element', {
    vuetify: vuetify,
    components: { 
        VApp, 
        HomePage 
    },
    template: `<v-app id='app'><home-page></home-page></v-app>`
})