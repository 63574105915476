<template>
<div>
  <section>
    <header class="d-flex justify-center">
      <h2>Access kinetic and photochemical data</h2>
    </header>
    <v-container>
      <v-row>
        <v-col cols="12" :xl="6">
          <article>
            <v-card>
              <v-card-title>
                Browse by reaction categories 
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>Click on gas phase or photolysis or heterogeneous or aqueous phase button and select a specific category to view all datasheets and recommanded values for the selected category.</span>
                </v-tooltip>
              </v-card-title>
              <v-card-actions class="flex-wrap">
                
                <v-menu
                    :nudge-bottom="35"
                    :max-height="400"
                    open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                        >
                            gas phase
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in gasPhaseItems"
                            :key="index"
                        >
                            <v-list-item-title>
                                <a :href="item.link">{{ item.title }}</a>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-menu
                    :nudge-bottom="35"
                    :max-height="400"
                    open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                        >
                            photolysis
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in photolysisItems"
                            :key="index"
                        >
                            <v-list-item-title>
                                <a :href="item.link">{{ item.title }}</a>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-menu
                    :nudge-bottom="35"
                    :max-height="400"
                    open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                        >
                            heterogeneous
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in heterogeneousItems"
                            :key="index"
                        >
                            <v-list-item-title>
                                <a :href="item.link">{{ item.title }}</a>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-menu
                    :nudge-bottom="35"
                    :max-height="400"
                    open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                        >
                            aqueous phase
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in aqueousPhaseItems"
                            :key="index"
                        >
                            <v-list-item-title>
                                <a :href="item.link">{{ item.title }}</a>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
              </v-card-actions>
            </v-card>
          </article>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :lg="6">
          <article>
            <v-card>
              <v-card-title>
                Search by molecule 
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>Click on 'Search Now' to search all gas-phase reactions, by species name, formula, InChI and smiles..</span>
                </v-tooltip>
              </v-card-title>
              <v-card-actions>
                <v-btn text href="https://iupac.aeris-data.fr/search">
                  Search now
                </v-btn>
              </v-card-actions>
            </v-card>
          </article>
        </v-col>
        <v-col cols="12" :lg="6">
          <article>
            <v-card>
              <v-card-title>
                Keeping track of changes 
                
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>Click on  'Look Now' to search all recently added and updated datasheets.</span>
                </v-tooltip>
              </v-card-title>

              <v-card-subtitle></v-card-subtitle>

              <v-card-actions>
                <v-btn text href="https://iupac.aeris-data.fr/recent-changes">
                  Look now
                </v-btn>
              </v-card-actions>
            </v-card>
          </article>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <section>
    <header class="d-flex justify-center">
      <h2>Understanding data</h2>
    </header>
    <article>
      <v-container>
        <v-row class="justify-center mb-12">
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-h5">
                <div class="flex-grow-0">Guides to the Datasheets</div>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small
                          class="flex-grow-0"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <span>For more details about methodology, feel free to download our guides for gas phase and hétérogeneous reaction.</span>
                    </v-tooltip>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list>
                      <v-list-item>
                        <a target="_blank" :href="this.$prodPrefix+'guides/Guide_to_Gas-Phase_Datasheets_Final_Oct_2017.pdf'" download>Guide to gas phase datasheets</a>
                      </v-list-item>
                      <v-list-item>
                        <a target="_blank" :href="this.$prodPrefix+'guides/NewHetIntroOct2009.pdf'" download>Guide to heterogeneous datasheets</a>
                      </v-list-item>
                      <v-list-item>
                        <a target="_blank" :href="this.$prodPrefix+'guides/Reaction_order.pdf'" download>Order of reactions within a family</a>
                      </v-list-item>
                    </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-container>
    </article>
  </section>
</div>
</template>

<script>
export default {
  name: 'Home',
  
  data: () => ({
      gasPhaseItems: [
        { title: 'all', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/classifications/gap' },
        { title: 'Ox species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/Ox' },
        { title: 'HOx species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/HOx' },
        { title: 'NOx species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/NOx' },
        { title: 'SOx species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/SOx' },
        { title: 'aromatic Ox', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/Ox_AROM' },
        { title: 'aromatic + Hox', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/HOx_AROM' },
        { title: 'aromatic NO3', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/NO3_AROM' },
        { title: 'organics Ox + VOC', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/Ox_VOC' },
        { title: 'organics HOx + VOC', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/HOx_VOC' },
        { title: 'organics NO3 + VOC', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/NO3_VOC' },
        { title: 'organics X + VOC', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/X_VOC' },
        { title: 'organic ClOx', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/oClOx' },
        { title: 'organic BrOx', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/oBrOx' },
        { title: 'organic FOx', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/oFOx' },
        { title: 'organic IOx', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/oIOx' },
        { title: 'organics peroxy radicals', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/ROO_' },
        { title: 'organics: alkoxy radicals', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/RO_' },
        { title: 'O2 + organic radicals', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/R_Oxygen' },
        { title: 'organic ClOx oxy radical', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/oRClOx' },
        { title: 'organic : aromatic radicals', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/AROM_RAD' },
        { title: 'inorganic fluorinated species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/iFOx' },
        { title: 'inorganic chlorinated species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/iClOx' },
        { title: 'inorganic brominated species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/iBrOx' },
        { title: 'inorganic iodinated species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/iIOx' },
        { title: 'Criegee intermediates', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/CGI_' },
      ],
      photolysisItems: [
        { title: 'all', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/classifications/pho' },
        { title: 'Ox species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/POx' },
        { title: 'HOx species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/PHOx' },
        { title: 'NOx species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/PNOx' },
        { title: 'organic species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/P' },
        { title: 'fluorinated species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/PF' },
        { title: 'chlorinated species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/PCl' },
        { title: 'brominated species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/PBr' },
        { title: 'iodinated species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/PI' },
        { title: 'SOx species', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/PSOx' },
      ],
      heterogeneousItems: [
        { title: 'all', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/classifications/het' },
        { title: 'ice', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/HI' },
        { title: 'mineral dust', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/MD' },
        { title: 'SAM/SAD/SAT', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/HSTD' },
        { title: 'NAT/NAD', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/HNDT' },
        { title: 'water', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/HET_H2OL_' },
        { title: 'aqueous halids', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/HET_SALTS_' },
        { title: 'other liquids', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/HET_O_' },
        { title: 'H2SO4', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/HET_SL_' },
        { title: 'organic surfaces', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/Het_Org' },
      ],
      aqueousPhaseItems: [
        { title: 'all', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/classifications/aqp' },
        { title: 'systematic OH', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/AQ_OH_' },
        { title: 'Systematic OH + inorganics', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/AQ_OH_inorg_' },
        { title: 'Systematic NO3 + organics', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/AQ_NO3_org_' },
        { title: 'thematic glyoxal', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/AQ_TH1_GLYX_' },
        { title: 'thematic methyl glyoxal', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/AQ_TH1_MGLY_' },
        { title: 'thematic NO3', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/AQ_TH1_NO3_' },
        { title: 'thematic OH', link: 'https://iupac.aeris-data.fr/catalogue/#/catalogue/categories/AQ_TH1_OH_' },
      ],
    }),
}
</script>

<style scoped>
.v-card {
  margin-bottom: 20px;
}
.v-list-item__title > a {
    text-decoration: none;
}
</style>